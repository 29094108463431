<template>
    <div class="home-content">
        <el-container style="width: 100%;height: 100%">
            <el-header class="system-header" v-if="roleFlag!=='4'">
                <h3 style="color: #ffffff;text-align: center">甘肃省社会体育管理中心 <br> 赛事评分系统</h3>
                <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link" style="color: #ffffff">
          <span v-if="roleFlag==='2'">{{userObj.name}} {{userObj.mainStatus===1?'主':''}}评委, 你好</span>
          <span v-if="roleFlag==='3'">赛事主持，你好</span>
       <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="quitLogin">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
            <el-container style="height: 100%">
                <el-main>
                    <router-view/>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import {quitLogin} from "../api/adminLogin";
    import common from "../utils/common";

    export default {
        name: 'Home',
        data() {
            return {
                userObj: {},
                roleFlag: null,
                //比赛信息
                eventInfo: null
            }
        },
        created() {
            //1管理 2评委 3主持 4大屏端
            this.roleFlag = sessionStorage.getItem('roleFlag');
            this.userObj = JSON.parse(sessionStorage.getItem('userInfo'));
            this.eventInfo = JSON.parse(sessionStorage.getItem('event'));
            //评委角色
            if (this.roleFlag === '2') {
                if (this.userObj.mainStatus === 1) {
                    //主评委
                    this.initWebSocket(this.userObj.id);
                    if (this.$route.path !== '/chiefJudge') {
                        this.$router.push('/chiefJudge')
                    }
                } else {
                    if (this.$route.path !== '/score') {
                        this.$router.push('/score')
                    }
                }
                //主持
            } else if (this.roleFlag === '3') {
                this.initWebSocket(new Date().getTime());
                //此处需要判断进行到哪一步了
                if (this.$route.path !== '/hostPage') {
                    this.$router.push('/hostPage')
                }
                //大屏端
            } else if (this.roleFlag === '4') {
                this.initWebSocket(new Date().getTime());
                //此处需要判断进行到哪一步了
                if (this.$route.path !== '/beforeShow') {
                    this.$router.push('/beforeShow')
                }
            }
        },
        methods: {
            handleCommand(command) {
                if (command === 'quitLogin') {
                    this.logout();
                }
            },
            logout() {
                this.$confirm('确定退出登录, 是否继续?', '注销', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const {data} = await quitLogin()
                    if (data.code === 200) {
                        window.sessionStorage.clear();
                        await this.$router.push('/judgesLogin')
                        this.$message({
                            type: 'success',
                            message: '注销成功!'
                        });
                    }
                })
            },
            initWebSocket(userId) {
                this.$disconnect();
                this.$connect(common.ws  + userId);
            },
        },
        // sockets: {
        //     // 连接成功
        //     onopen() {
        //         console.log("连接websocket成功");
        //     },
        //     // 接收消息
        //     onmessage(e) {
        //         // window.location.reload();
        //     },
        //     // 连接报错
        //     onerror() {
        //         console.log("连接websocket出错");
        //     },
        //     // 关闭连接
        //     onclose() {
        //         console.log("websocket关闭");
        //     },
        // },
        beforeDestroy() {
            // 销毁websocket
            this.$disconnect();
        }
    }
</script>
<style lang="less" scoped>
    .home-content {
        width: 100%;
        height: 100%;

        .el-container {
            width: 100%;
            height: 100%;

            .system-header {
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #bf353b;
            }

            /deep/ .el-aside {
                height: 100%;
                background-color: #f46e55;

                .aside-header {
                    width: 100%;
                    height: 100px;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            /deep/.el-card__body, .el-main {
                padding: 0 !important;
            }
        }
    }
</style>
